import React from 'react';

export const stripAemUrlPrefix = (object, keyToStrip, siteInfo) => {
  const keys = Array.isArray(keyToStrip) ? keyToStrip : [keyToStrip];

  keys.forEach(urlKey => {
    let keyArray = urlKey.split('.');
    keyArray.reduce((obj, acc, index) => {
      const value = obj[acc];

      if (Array.isArray(value)) {
        return value.map(item =>
          stripAemUrlPrefix(item, keyArray[index + 1], siteInfo)
        );
      }

      if (!value) {
        return '';
      }

      if (index === keyArray.length - 1 && value && !value.includes('http')) {
        const { pages, homepage: homeUrl, urlPrefix } = siteInfo;
        const homepage = pages.find(item => homeUrl === item.path);

        if (homepage) {
          const isPublisherUrl = !homepage.aemPath.includes(urlPrefix);

          const aemHome = isPublisherUrl
            ? homepage.aemPath.replace(/^.+?[/]/, urlPrefix).slice(0, -1)
            : homepage.aemPath.slice(0, -1);

          if (value.includes(aemHome)) {
            obj[acc] = value.replace(aemHome, homepage.path);
            return obj[acc];
          }
        }

        if (value.includes(urlPrefix)) {
          obj[acc] = value.replace(urlPrefix, '');
        }
      }

      return obj[acc];
    }, object);
  });

  return object;
};

export const withUrlStripping = (Component, urlProps, siteInfo) => {
  const ComponentWithUrlStripping = props => {
    const newProps = stripAemUrlPrefix({ ...props }, urlProps.key, siteInfo);
    return <Component {...newProps} />;
  };

  ComponentWithUrlStripping.displayName = `withUrlStripping(${
    Component.displayName || Component.name || 'Component'
  })`;

  return ComponentWithUrlStripping;
};
